import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { legacyBreakpoints } from '../utils/media'
import CTA from './CTA'

const backgroundColors = {
  Blue: 'var(--color-background-bluish-purple)',
  Green: 'var(--color-background-mint)',
  Pink: 'var(--color-background-pink)',
  PowderBlue: 'var(--color-background-powder-blue)',
  Sand: 'var(--color-background-sand)',
  None: 'transparent',
}

const IconContainer = styled.div`
  border-radius: var(--border-radius-large);
  padding: 32px;
  text-align: left;
  width: calc(100% / ${({ $maxColumns }) => $maxColumns} - 32px);
  position: relative;

  background-color: ${({ $bgColor }) => ($bgColor ? backgroundColors[$bgColor] : 'transparent')};

  text-align: ${({ $center }) => ($center ? 'center' : 'left')};

  @media screen and (max-width: ${legacyBreakpoints.tablet}) {
    width: calc(100% / 2 - 32px);
  }

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    text-align: center;
    width: 100%;
  }

  &:last-of-type {
    .dashes,
    .mobileDashes {
      display: none;
    }
  }
`

const StyledCTA = styled(CTA)`
  && {
    background-color: var(--color-background-primary);
    color: var(--color-typography-on-primary);
    border: 1px solid var(--color-background-primary);
    margin: 24px 0 0;
  }
`

const Icon = styled(GatsbyImage)`
  margin: ${({ $center }) => ($center ? '0 auto' : 'unset')};

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    margin: 0 auto;
  }
`

const IconImageContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const Dashes = styled.div`
  width: 60%;
  border-bottom: 2px dashed;
  position: absolute;
  top: 80px;
  left: 76%;
  color: var(--color-primary);

  @media screen and (max-width: ${legacyBreakpoints.tablet}) {
    display: none;
  }

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    width: 96px;
    bottom: -16px;
    left: 0;
    right: 0;
    top: unset;
    margin: auto;
    transform: rotate(90deg);

    &.mobileDashes {
      display: block;
    }
  }
`

const Price = styled.p`
  && {
    color: var(--color-typography-dark);
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    margin: 16px 0 0;
  }
`

const Description = styled.p`
  color: var(--color-typography-dark);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  padding: 0;
`

const Title = styled.h3`
  color: var(--color-typography-dark);
  font-size: 22px;
  line-height: 24px;
  margin: 16px 0 24px;
  padding: 0;
`

const IconText = ({
  backgroundColor,
  maxColumns,
  image,
  title,
  description,
  imageAlt,
  center,
  cta,
  displayConnectingDashes,
  price,
}) => {
  return (
    <IconContainer
      $bgColor={backgroundColor}
      $maxColumns={maxColumns}
      $center={center}>
      <IconImageContainer>
        {image && (
          <Icon
            $center={center}
            image={image}
            alt={imageAlt}
          />
        )}
        {displayConnectingDashes && <Dashes className="dashes" />}
      </IconImageContainer>
      <Price>{price}</Price>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
      {cta && (
        <StyledCTA
          to={cta.url}
          $primary>
          {cta.text.value}
        </StyledCTA>
      )}
      {displayConnectingDashes && <Dashes className="mobileDashes" />}
    </IconContainer>
  )
}

export default IconText

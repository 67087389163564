import { getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import IconText from './IconText'
import Wrapper from './Wrapper'

const Container = styled.div`
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > * {
    margin: 16px;
  }
`

const SectionWrapper = styled(Wrapper)`
  margin: 48px auto 0;
`

const PageSectionIconSet = ({ backgroundColor, maxColumns = 3, icons, centerIcons, displayConnectingDashes }) => {
  return (
    <SectionWrapper>
      <Container>
        {icons
          .filter((icon) => icon.title)
          .map((icon) => (
            <IconText
              key={icon.id}
              title={icon.title}
              description={icon.description}
              image={getImage(icon.illustration?.localFile || icon.icon?.localFile)}
              backgroundColor={icon.background_color || backgroundColor}
              maxColumns={maxColumns}
              center={centerIcons}
              cta={icon?.cta}
              imageAlt={icon.image_alt}
              displayConnectingDashes={displayConnectingDashes}
              price={icon.price}
            />
          ))}
      </Container>
    </SectionWrapper>
  )
}

export default PageSectionIconSet
